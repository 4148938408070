import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mt-5 mb-5">&nbsp;</div>
    <div className="container text-center mt-5 mb-5">
      <h1 className="">404 NOT FOUND</h1>
      <p className="mt-3">
        ขออภัยไม่พบหน้าที่คุณค้นหา
        ดูเหมือนว่าหน้าเว็บที่คุณพยายามเข้าถึงไม่มีอยู่อีกต่อไปหรืออาจจะย้ายไปยังหน้าเว็บเพจอื่น
      </p>
      <Link to={"/"} className="mt-4 btn btn-dark title">
        กลับสู่หน้าหลัก
      </Link>
    </div>
    <div className="mt-5 mb-5">&nbsp;</div>
  </Layout>
)

export default NotFoundPage
